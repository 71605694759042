const PAGE_TEXTS = {
    title: 'Anmelden',
    description: 'Bei Tebuto anmelden, um einen Termin mit Ihrer/Ihrem Therapeut:in zu vereinbaren.',
    logo: 'Tebuto Logo',
    loginForm: {
        step1: 'Schritt 1',
        step1Description: 'Anmeldung',
        step2: 'Schritt 2',
        step2Description: 'Verifizierung',
        requestCode: {
            login: 'Anmelden',
            confirm: 'Bestätigen',
            pleaseEnterEmail: 'Bitte geben Sie Ihre E-Mail Adresse ein, damit wir Ihnen einen Zugangscode zusenden können.',
            noAccountYet: 'Wie Sie ein Konto erhalten'
        },
        sendCode: {
            enterCode: 'Zugangscode eingeben',
            enterCodeDescription: 'Wir haben Ihnen einen Zugangscode per E-Mail zugesendet. Bitte geben Sie diesen Code hier ein:',
            confirm: 'Bestätigen',
            useDifferentEmail: 'Andere E-Mail Adresse verwenden',
            noCodeReceived: 'Keinen Code erhalten?',
            newCodeCooldown: (cooldownSeconds: number) => `In ${cooldownSeconds} Sekunden können Sie einen neuen Code anfordern`,
            requestNewCodeDialog: {
                title: 'Keinen Code erhalten?',
                description1:
                    'Manchmal brauchen E-Mails ihre Zeit. Bitte warten Sie noch einige Minuten und schauen Sie auch in Ihren Spam-Ordner. Stellen Sie sicher, dass die angegebene E-Mail-Adresse ',
                description2: ' korrekt ist.',
                submit: 'Neuen Code Anfordern',
                newCodeSent: 'Ein neuer Code wurde an Ihre E-Mail Adresse gesendet',
                invalidCode: 'Ungültiger Code'
            },
            errors: {
                codeMandatory: 'Code ist erforderlich',
                onlyDigits: 'Der Code besteht ausschließlich aus Ziffern',
                emailMandatory: 'E-Mail Adresse ist erforderlich',
                invalidEmail: 'Ungültige E-Mail Adresse'
            }
        }
    }
}

export default PAGE_TEXTS
