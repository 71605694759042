import { Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from '@tebuto/functions'
import { ButtonSpinner } from '@tebuto/layout/Spinner'
import COMPONENT_TEXTS from '@tebuto/translations'
import SmallText from '@tebuto/typography/SmallText'
import React, { Dispatch, SetStateAction } from 'react'
import LargeText from '../typography/LargeText'
import MediumText from '../typography/MediumText'
import { useSelectFirstInput } from './hooks'

export interface IMutationHandlerResponse {
    title: string
    text: string
}

export interface IConfirmationDialogue {
    title: string
    subTitle?: string
    description?: string | React.ReactNode
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    submitButtonText?: string
    cancelButtonText?: string
    onSubmit: () => void
    onCancel?: () => void
    submitButtonEnabled?: boolean
    isLoading?: boolean
    children?: React.ReactNode
}

function ConfirmationDialogue({
    open,
    setOpen,
    onSubmit,
    onCancel = () => {},
    submitButtonText = COMPONENT_TEXTS.modals.submitButtonText,
    cancelButtonText = COMPONENT_TEXTS.modals.cancelButtonText,
    submitButtonEnabled = true,
    isLoading,
    title,
    description,
    subTitle,
    children
}: IConfirmationDialogue) {
    const { modalBodyRef } = useSelectFirstInput()

    function close() {
        setOpen(false)
        onCancel()
    }

    return (
        <Transition show={open}>
            <div className="fixed inset-0 z-50 overflow-visible" onClick={close}>
                <TransitionChild
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-50">
                    <div className="flex min-h-screen items-center justify-center text-center sm:items-center">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                                onClick={e => e.stopPropagation()}
                            >
                                {/* Header */}
                                <div className="bg-primary-25 border-b border-gray-300 rounded-t-lg">
                                    {/* Close Icon */}
                                    <button type="button" className="absolute right-4 top-4" onClick={close}>
                                        <span className="sr-only">{COMPONENT_TEXTS.modals.closeDialogue}</span>
                                        <XMarkIcon className="h-6 w-6  text-gray-500 hover:cursor-pointer" />
                                    </button>
                                    {/* Title */}
                                    <div className="px-6 py-4">
                                        <div>
                                            {subTitle && <SmallText className="mt-2">{subTitle}</SmallText>}
                                            <LargeText className="text-lg font-semibold text-gray-600">{title}</LargeText>
                                        </div>
                                    </div>
                                </div>

                                {/* Content */}
                                <div className="mb-4 px-6 py-6 w-full" ref={modalBodyRef}>
                                    <>
                                        {description && <MediumText className="font-medium">{description}</MediumText>}
                                        {children}
                                    </>
                                </div>

                                {/* Buttons */}
                                <div className="bg-primary-25 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between border-t border-gray-300 rounded-b-lg">
                                    {
                                        <button
                                            type="button"
                                            className={classNames(
                                                'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto text-sm',
                                                submitButtonEnabled ? 'bg-primary-600 hover:bg-primary-700 hover:cursor-pointer' : 'bg-primary-200 hover:cursor-not-allowed'
                                            )}
                                            disabled={!submitButtonEnabled}
                                            onClick={() => {
                                                onSubmit()
                                                if (isLoading === undefined) {
                                                    close()
                                                } else {
                                                    function closeWhenLoadingFinished() {
                                                        setTimeout(() => {
                                                            if (isLoading) {
                                                                closeWhenLoadingFinished()
                                                            } else {
                                                                close()
                                                            }
                                                        }, 10)
                                                    }
                                                    closeWhenLoadingFinished()
                                                }
                                            }}
                                        >
                                            {isLoading ? <ButtonSpinner /> : submitButtonText}
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-primary-25 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto text-sm"
                                        onClick={() => {
                                            if (isLoading === undefined) {
                                                close()
                                            } else {
                                                function closeWhenLoadingFinished() {
                                                    setTimeout(() => {
                                                        if (isLoading) {
                                                            closeWhenLoadingFinished()
                                                        } else {
                                                            close()
                                                        }
                                                    }, 50)
                                                }
                                                closeWhenLoadingFinished()
                                            }
                                        }}
                                    >
                                        {cancelButtonText}
                                    </button>
                                </div>
                            </div>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Transition>
    )
}

export default ConfirmationDialogue
